/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { U2FPack } from "../../types/u2f-pack";

export type ParamsType = { uuid: string; role: string; u2fPack: U2FPack };
export type ResponseType = void;
export const Endpoint = "admin/grant-role";
export const Method = "POST";

export type Plug = {
    params: ParamsType,
    response: ResponseType,
    method: typeof Method,
    endpoint: typeof Endpoint,
};export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"},"role":{"type":"string"},"u2fPack":{"type":"object","properties":{"challengeId":{"type":"string"},"credential":{"$ref":"#/definitions/AuthenticationCredentialJSON"}},"required":["challengeId","credential"]}},"required":["role","u2fPack","uuid"],"definitions":{"AuthenticationCredentialJSON":{"type":"object","properties":{"rawId":{"type":"string"},"response":{"$ref":"#/definitions/AuthenticatorAssertionResponseJSON"},"clientExtensionResults":{"$ref":"#/definitions/AuthenticationExtensionsClientOutputs"},"id":{"type":"string"},"type":{"type":"string","enum":["public-key"]}},"required":["clientExtensionResults","id","rawId","response","type"]},"AuthenticatorAssertionResponseJSON":{"type":"object","properties":{"authenticatorData":{"type":"string"},"clientDataJSON":{"type":"string"},"signature":{"type":"string"},"userHandle":{"type":"string"}},"required":["authenticatorData","clientDataJSON","signature"]},"AuthenticationExtensionsClientOutputs":{"type":"object","properties":{"appid":{"type":"boolean"},"credProps":{"type":"object","properties":{"rk":{"type":"boolean"}}},"uvm":{"type":"array","items":{"type":"array","items":{"type":"number"}}}}}},"$schema":"http://json-schema.org/draft-07/schema#"}