/* eslint-disable @typescript-eslint/prefer-optional-chain */
import React from 'react';
import { LoginUI } from '@hiyllo/ux/login-ui';

import * as QueryLoginBP from '../blueprints/login/query-login';
import * as LoginWithEmailAndPasswordBP from '../blueprints/login/login-with-email-and-password';
import * as RegisterWithEmailOTPBP from '../blueprints/register/register-with-email-otp';
import * as LoginWithEmailOTPBP from '../blueprints/login/login-with-email-otp';
import * as ResetPasswordBP from '../blueprints/accounts/reset-password';
import * as StartLoginU2FAuthentcationBP from '../blueprints/login/start-u2f-login-authentication';
import {
  SESSION_TOKEN_KEY, seamlessAuth, seamlessClient
} from '../seamless-client';
import { MoopsyError } from '@moopsyjs/core';
import { hashString } from '../accounts/helpers/hash-password';
import { startAuthentication } from '@simplewebauthn/browser';
import { U2FPack } from '../types/u2f-pack';
import { Card } from '@hiyllo/ux/surface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBriefcase } from '@fortawesome/pro-light-svg-icons';
import { AuthExtensionStatus } from '@moopsyjs/react/main';
import { checkCSRF } from '../lib/csrf';
import { LoadingPage } from '@hiyllo/ux/standard-loader';
import { useFederationSlug } from '../hooks/federation/use-slug';

export const Login = React.memo(function Login (): JSX.Element {
  const queryLoginMutation = seamlessClient.useMutation<QueryLoginBP.Plug>(QueryLoginBP);
  const registerWithEmailOTPMutation = seamlessClient.useMutation<RegisterWithEmailOTPBP.Plug>(RegisterWithEmailOTPBP);
  const loginWithEmailAndPasswordMutation = seamlessClient.useMutation<LoginWithEmailAndPasswordBP.Plug>(LoginWithEmailAndPasswordBP);
  const loginWithEmailOTPMutation = seamlessClient.useMutation<LoginWithEmailOTPBP.Plug>(LoginWithEmailOTPBP);
  const startLoginU2FMutation = seamlessClient.useMutation<StartLoginU2FAuthentcationBP.Plug>(StartLoginU2FAuthentcationBP);
  const resetPasswordMutation = seamlessClient.useMutation<ResetPasswordBP.Plug>(ResetPasswordBP);
  const fedSlug = useFederationSlug();
  const from: 'support' | 'work' = (new window.URLSearchParams(window.location.search).get('from') as any) ?? 'work';

  const [authenticatorType, setAuthenticatorType] = React.useState<'password' | 'email-otp' | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [hasAccount, setHasAccount] = React.useState<boolean>(false);
  const [otpSessionUUID, setOtpSessionUUID] = React.useState<string | null>(null);
  const isLoading = queryLoginMutation.isLoading || registerWithEmailOTPMutation.isLoading || loginWithEmailAndPasswordMutation.isLoading || startLoginU2FMutation.isLoading || loginWithEmailOTPMutation.isLoading;
  const mfaCredentialRef = React.useRef<U2FPack | null>(null);

  const authStatus = seamlessAuth.useAuthStatus();
  React.useEffect(() => {
    if (authStatus === AuthExtensionStatus.loggedIn) {
      const then = new window.URLSearchParams(window.location.search).get('then');

      if (then != null && then.startsWith('https://www.hiyllo.cloud/')) {
        checkCSRF(then);
        window.location.href = then;
      }
    }
  }, [authStatus]);

  const onQueryLogin = React.useCallback(async (email: string) => {
    try {
      const res = await queryLoginMutation.call({
        email,
        slug: fedSlug ?? null
      });

      if (res.status === 'password') {
        setHasAccount(true);
      } else {
        setHasAccount(res.hasAccount);
        setOtpSessionUUID(res.sessionUUID);
      }

      setAuthenticatorType(res.status);
    } catch (e) {
      setErrorMessage((e as MoopsyError).message);
    }
  }, [fedSlug, queryLoginMutation]);

  const onEmailOTP = React.useCallback(async (code: string) => {
    try {
      if (otpSessionUUID == null) {
        throw new Error('!otpSessionUUID');
      }

      const res = await (hasAccount ? loginWithEmailOTPMutation : registerWithEmailOTPMutation).call({
        sessionUUID: otpSessionUUID,
        code
      });

      window.localStorage.setItem(SESSION_TOKEN_KEY, JSON.stringify(res));
      window.location.reload();
    } catch (e) {
      const err = (e as MoopsyError);
      if (err.message.startsWith('mfa-required')) {
        if (mfaCredentialRef.current != null) {
          setErrorMessage('Failed to authenticate with MFA. Contact Hiyllo support.');
          throw err;
        }

        const { options, challengeId } = await startLoginU2FMutation.call({ userId: err.message.slice(err.message.indexOf('/') + 1) });
        const res = (await startAuthentication(options));

        if (res == null) {
          throw new Error('Failed to register');
        }

        mfaCredentialRef.current = {
          credential: res,
          challengeId
        };

        void onEmailOTP(code);
      } else {
        console.error(e);
        setErrorMessage(err.message);
      }
    }
  }, [otpSessionUUID, hasAccount, loginWithEmailOTPMutation, registerWithEmailOTPMutation, startLoginU2FMutation]);

  const onPassword = React.useCallback(async (opts: {email: string, password: string}) => {
    const password = await hashString(opts.password);

    try {
      const res = await loginWithEmailAndPasswordMutation.call({
        email: opts.email,
        password,
        u2fPack: mfaCredentialRef.current,
        federation: fedSlug ?? null
      });

      window.localStorage.setItem(SESSION_TOKEN_KEY, JSON.stringify(res));

      const then = new window.URLSearchParams(window.location.search).get('then');

      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (then != null && then.startsWith('https://www.hiyllo.cloud/')) {
        window.location.href = then;
      } else {
        window.location.reload();
      }
    } catch (e) {
      const err = (e as MoopsyError);
      if (err.message.startsWith('mfa-required')) {
        if (mfaCredentialRef.current != null) {
          setErrorMessage('Failed to authenticate with MFA. Contact Hiyllo support.');
          throw err;
        }

        const { options, challengeId } = await startLoginU2FMutation.call({ userId: err.message.slice(err.message.indexOf('/') + 1) });
        const res = (await startAuthentication(options));

        if (res == null) {
          throw new Error('Failed to register');
        }

        mfaCredentialRef.current = {
          credential: res,
          challengeId
        };

        void onPassword(opts);
      } else {
        console.error(e);
        setErrorMessage(err.message);
      }
    }
  }, [fedSlug, loginWithEmailAndPasswordMutation, startLoginU2FMutation]);

  const onSubmit = React.useCallback((value: {
    email: string
    authenticator: string
  }) => {
    if (authenticatorType == null) {
      void onQueryLogin(value.email);
    } else if (authenticatorType === 'email-otp') {
      void onEmailOTP(value.authenticator.toUpperCase());
    } else if (authenticatorType === 'password') {
      void onPassword({
        email: value.email,
        password: value.authenticator
      });
    }
  }, [authenticatorType, onEmailOTP, onPassword, onQueryLogin]);

  const preFixedEmail = new window.URLSearchParams(
    window.location.search
  ).get('em');
  const queriedWithPreFixedEmailRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (preFixedEmail != null && typeof preFixedEmail === 'string' && preFixedEmail.length >= 5 && !queriedWithPreFixedEmailRef.current) {
      void onQueryLogin(preFixedEmail);
      queriedWithPreFixedEmailRef.current = true;
    }
  }, [onQueryLogin, preFixedEmail]);

  const onForgotPassword = React.useCallback(async (email: string) => {
    const federation = window.location.host.endsWith('f.hiyllo.cloud') ? window.location.host.split('.')[0] : null;
    await resetPasswordMutation.call({
      email,
      federation
    });
  }, [resetPasswordMutation]);

  if (authStatus === AuthExtensionStatus.loggedIn) {
    return <LoadingPage/>;
  }

  return (
    <LoginUI
      fixedIdentifier={preFixedEmail}
      disableIdentifier={preFixedEmail != null}
      authenticatorType={authenticatorType ?? 'password'}
      logoPath="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient-logo-white.png"
      showAuthenticator={otpSessionUUID != null || authenticatorType != null}
      onSubmit={onSubmit}
      errorMessage={errorMessage}
      hasAccount={hasAccount}
      isLoading={isLoading}
      onForgotPassword={authenticatorType === 'password' ? onForgotPassword : undefined}
      beforeCard={
        fedSlug != null
          ? (
            <div style={{
              marginBottom: 20,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 7.5
            }}>
              <FontAwesomeIcon icon={faBriefcase} />
              <div>Logging in to {fedSlug}.hiyllo.business</div>
            </div>
          )
          : null
      }
      afterCard={
        fedSlug != null
          ? null
          : <Card
            color="background2"
            style={{
              width: 300,
              marginTop: 10,
              cursor: 'pointer',
              userSelect: 'none'
            }}
            onClick={() => {
              window.location.href = '/f/login';
            }}
          >
            <div style={{ fontSize: 12.5 }}>
            To login to Hiyllo for Business products like Hiyllo Work click here <FontAwesomeIcon icon={faArrowRight}/>
            </div>
          </Card>
      }
    />
  );
});
