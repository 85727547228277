/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { AuthenticationMethodEnum, ExtendedUserType } from "../../types/extended-user-type";
import { VoidSchemaType } from "../../types/json-schema";

export type ParamsType = null;
export type ResponseType = { user: ExtendedUserType, roles: string[], pam: AuthenticationMethodEnum };
export const Endpoint = 'accounts/get-self';
export const Method = 'GET';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}

export const paramsSchema = VoidSchemaType;