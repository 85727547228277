import React from 'react';
import { Input } from '@hiyllo/ux/input';
import { Card } from '@hiyllo/ux/surface';
import { styled } from '@hiyllo/ux/styled';
import { useNavigate } from 'react-router-dom';
import { useShowAlert } from '@hiyllo/ux/dialogs';
import { MoopsyError } from '@moopsyjs/core/main';
import { CircleButton } from '@hiyllo/ux/circle-button';
import { HeaderRow, Typography } from '@hiyllo/ux/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBriefcase } from '@fortawesome/pro-light-svg-icons';

import { seamlessAuth, seamlessClient } from '../seamless-client';
import * as QueryFederationBP from '../blueprints/federation/query-federation';
import { AuthExtensionStatus } from '@moopsyjs/react/main';
import { checkCSRF } from '../lib/csrf';
import { LoadingPage } from '@hiyllo/ux/standard-loader';

const Page = styled('div', ({ $theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: $theme.background1,
  color: $theme.foreground,
  fontFamily: 'hiyllo'
}));

export const LoginFederation = React.memo(function LoginFederation (): JSX.Element {
  const from: 'support' | 'work' = (new window.URLSearchParams(window.location.search).get('from') as any) ?? 'work';
  const continuePath = (new window.URLSearchParams(window.location.search).get('continue') as string) ?? 'https://www.hiyllo.io';

  const queryFederationMutation = seamlessClient.useMutation<QueryFederationBP.Plug>(QueryFederationBP);

  const authStatus = seamlessAuth.useAuthStatus();
  React.useEffect(() => {
    if (authStatus === AuthExtensionStatus.loggedIn) {
      window.location.href = continuePath;
    }
  }, [authStatus, continuePath]);

  checkCSRF(continuePath);

  const [slug, setSlug] = React.useState('');
  const showAlert = useShowAlert();

  const navigate = useNavigate();

  const onClick = React.useCallback(() => {
    if (slug.length > 0) {
      queryFederationMutation.call({ slug }).then(() => {
        navigate(`/f/login/${slug}?${new window.URLSearchParams([...new window.URLSearchParams(window.location.search)].map(([key, value]) => ([key, value.replace('<tenant>', slug)]))).toString()}`);
      }).catch(err => {
        void showAlert({
          title: 'Error',
          message: (err as MoopsyError).message
        });
      });
    }
  }, [navigate, queryFederationMutation, showAlert, slug]);

  if (authStatus === AuthExtensionStatus.loggedIn) {
    return <LoadingPage/>;
  }

  return (
    <Page>
      <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png" style={{
        height: 50,
        width: 50
      }}/>
      <div style={{ height: 20 }}/>
      <HeaderRow>
        <Typography.SubHeader>
          <FontAwesomeIcon icon={faBriefcase}/>
        </Typography.SubHeader>
        <Typography.SubHeader>
          Hiyllo for Business
        </Typography.SubHeader>
      </HeaderRow>
      <div style={{ height: 20 }}/>
      <div style={{ overflow: 'hidden' }}>
        <div className="AnimatedGlow">
          <Card color="background2">
            <div style={{ width: 300 }}>
              <Typography.Label>Enter your organization&apos;s .hiyllo domain</Typography.Label>
              <Input
                placeholder='yourcompany'
                inputStyle={{ textAlign: 'right' }}
                endEnhancer={<span>.hiyllo.{from}</span>}
                value={slug}
                onChangeValue={setSlug}
                fullWidth
                regexp={/^[a-zA-Z0-9]+$/}
                key="slug"
                autoFocus
              />
            </div>
          </Card>
        </div>
      </div>
      <div style={{ height: 20 }}/>
      <CircleButton
        icon={faArrowRight}
        size={40}
        onClick={onClick}
        secondary={slug.length < 1}
        isLoading={queryFederationMutation.isLoading}
      />
    </Page>
  );
});
