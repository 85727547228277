import * as StartU2FRegistrationBlueprint from '../blueprints/security/start-u2f-registration';
import * as CompleteU2FRegistrationBlueprint from '../blueprints/security/complete-u2f-registration';
import React from 'react';
import EJSON from 'ejson';
import { seamlessClient } from '../seamless-client';
import { startRegistration } from '@simplewebauthn/browser';
import { Button } from '@hiyllo/ux/button';

enum MFARegistrationPanelStages { loading, initial, awaitingDeviceRegistration, complete, fail }

export const MFARegistrationPanel = (): JSX.Element => {
  const [stage, setStage] = React.useState<MFARegistrationPanelStages>(MFARegistrationPanelStages.initial);
  const startU2fRegistrationMutation = seamlessClient.useMutation<StartU2FRegistrationBlueprint.Plug>(StartU2FRegistrationBlueprint);
  const completeU2fRegistrationMutation = seamlessClient.useMutation<CompleteU2FRegistrationBlueprint.Plug>(CompleteU2FRegistrationBlueprint);

  const onClickStartRegistration = async (): Promise<void> => {
    try {
      setStage(MFARegistrationPanelStages.loading);
      // eslint-disable-next-line no-useless-call
      const { options } = await startU2fRegistrationMutation.call(null);
      setStage(MFARegistrationPanelStages.awaitingDeviceRegistration);
      const attResp = await startRegistration(options);
      setStage(MFARegistrationPanelStages.loading);
      await completeU2fRegistrationMutation.call({ credentials: EJSON.stringify(attResp) });
      setStage(MFARegistrationPanelStages.complete);
    } catch (e: unknown) {
      console.error(e);
      setStage(MFARegistrationPanelStages.fail);
    }
  };

  if (stage === MFARegistrationPanelStages.loading) {
    return (
      <div>
              Loading...
      </div>
    );
  }

  if (stage === MFARegistrationPanelStages.fail) {
    return (
      <div>
              Failed to register.
      </div>
    );
  }

  if (stage === MFARegistrationPanelStages.awaitingDeviceRegistration) {
    return (
      <div>
              Proceed to register...
      </div>
    );
  }

  if (stage === MFARegistrationPanelStages.complete) {
    return (
      <div>
              MFA Setup!
      </div>
    );
  }

  if (stage === MFARegistrationPanelStages.initial) {
    return (
      <div>
        <Button onClick={() => { void onClickStartRegistration(); }} label="Enroll Security Key" isSecondary/>
      </div>
    );
  }

  return (
    <div>

    </div>
  );
};
