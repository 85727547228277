/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
/**
 * Create a token that allows transferring authentication between hiyllo.cloud subdomains
 */

export type ParamsType = {
  transferToken: string;
};
export type ResponseType = {
  authToken: string;
}
export const Endpoint = 'login/complete-transfer-auth';
export const Method = 'POST';
export const isPublic = true;

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}export const paramsSchema = {"description":"Create a token that allows transferring authentication between hiyllo.cloud subdomains","type":"object","properties":{"transferToken":{"type":"string"}},"required":["transferToken"],"$schema":"http://json-schema.org/draft-07/schema#"}