/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { HashedString } from "../../types/hashed-string";

export type ParamsType = {
  name: string;
  slug: string;
  
  username: string;
  email: string;
  password: HashedString;
};
export interface ResponseType {
  token: string;
  transferToken: string;
}
export const Endpoint = 'register/register-with-federation';
export const Method = 'POST';
export const isPublic = true;

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"name":{"type":"string"},"slug":{"type":"string"},"username":{"type":"string"},"email":{"type":"string"},"password":{"type":"object","properties":{"digest":{"type":"string"},"algorithm":{"type":"string"}},"required":["algorithm","digest"]}},"required":["email","name","password","slug","username"],"$schema":"http://json-schema.org/draft-07/schema#"}