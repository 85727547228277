/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { SSOApp } from "../../types/sso-apps";

export type ParamsType = {
  organizationUUID: string;
};
export type ResponseType = {
  apps: SSOApp[];
}
export const Endpoint = 'sso-apps/list-sso-apps';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}export const paramsSchema = {"type":"object","properties":{"organizationUUID":{"type":"string"}},"required":["organizationUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}