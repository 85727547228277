/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export function createJSONSchemaFromRequiredProperties(properties: any, optionalProperties={}) {
    return {
        // @ts-ignore
        type:"object",
        properties: {
            ...properties,
            ...optionalProperties
        },
        required: Object.keys(properties),
        additionalProperties: false,
    };
}
type SchemaTypeType = { type: string };
export const StringSchemaType: SchemaTypeType = { type: "string" };
export const NumberSchemaType = { type: "number" };
export const VoidSchemaType: SchemaTypeType = { type: "null" };
export const schemaOr = (types:Array<SchemaTypeType>) => {
    return { type: types.map((obj) => obj.type) };
};
export const JSDateSchema = { type: "object", format:"jsdate" };