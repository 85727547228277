import React from 'react';
import { styled } from '@hiyllo/ux/styled';
import * as GetSelfBP from '../blueprints/accounts/get-self';
import * as GetUsersBP from '../blueprints/admin/get-users';
import * as GrantRoleBP from '../blueprints/admin/grant-role';
import * as CommandeerAccountBP from '../blueprints/admin/commandeer-account';
import * as SetUserPasswordBP from '../blueprints/admin/set-user-password';
import * as UpgradeAccountToHiylloBP from '../blueprints/accounts/upgrade-account-to-hiyllo';
import { SESSION_TOKEN_KEY, seamlessClient } from '../seamless-client';
import { LoadingSpinner } from '@hiyllo/ux/loading-spinner';
import { useAuthenticateActionWithMFA } from './authenticate-action-with-mfa';
import { MoopsyError } from '@moopsyjs/core';
import { useShowDialog } from '@hiyllo/ux/dialogs';
import { hashString } from '../accounts/helpers/hash-password';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck } from '@fortawesome/pro-light-svg-icons';

const Container = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  color: $theme.foreground,
  height: '100%',
  overflowY: 'auto'
}));

export const Admin = React.memo(function Admin (props: {self: GetSelfBP.ResponseType}): JSX.Element {
  const getUsersQuery = seamlessClient.useQuery<GetUsersBP.Plug>(GetUsersBP, null);
  const commandeerAccountMutation = seamlessClient.useMutation<CommandeerAccountBP.Plug>(CommandeerAccountBP);
  const grantRoleMutation = seamlessClient.useMutation<GrantRoleBP.Plug>(GrantRoleBP);
  const setUserPasswordMutation = seamlessClient.useMutation<SetUserPasswordBP.Plug>(SetUserPasswordBP);
  const upgradeAccountToHiylloMutation = seamlessClient.useMutation<UpgradeAccountToHiylloBP.Plug>(UpgradeAccountToHiylloBP);
  const { authenticateActionWithMFA } = useAuthenticateActionWithMFA();
  const showDialog = useShowDialog();

  const changeUserPassword = React.useCallback((userId: string) => {
    void showDialog({
      title: 'Change User Password',
      message: `Enter new password for user ${userId}`,
      requireValue: true,
      password: true,
      onSubmit: async (value: string) => {
        const u2fPack = await authenticateActionWithMFA(`admin/set-user-password/${userId}`);
        await setUserPasswordMutation.call({
          userId,
          password: await hashString(value),
          u2fPack
        }).then(() => {
          alert('Success');
        });
      }
    });
  }, [authenticateActionWithMFA, setUserPasswordMutation, showDialog]);

  const commandeerAccount = React.useCallback((uuid: string) => {
    void authenticateActionWithMFA(`commandeer-account/${uuid}`).then((u2fPack) => {
      const commandeerPassword = window.prompt('Enter commandeer password');

      if (commandeerPassword == null) {
        return;
      }

      void commandeerAccountMutation.call({
        uuid,
        u2fPack,
        commandeerPassword
      }).then(res => {
        window.localStorage.setItem(SESSION_TOKEN_KEY, JSON.stringify(res));
        window.location.reload();
      });
    });
  }, [authenticateActionWithMFA, commandeerAccountMutation]);

  const upgradeToEmployee = React.useCallback((uuid: string) => {
    if (!confirm('Upgrade to employee?')) {
      return;
    }

    const username = prompt('Username?');

    if (username == null) {
      return;
    }

    void upgradeAccountToHiylloMutation.call({
      uuid,
      username
    }).then(() => {
      alert('Success');
    });
  }, [upgradeAccountToHiylloMutation]);

  const giveRole = React.useCallback((uuid: string) => {
    const role = prompt('Role?');

    if (role == null) {
      return;
    }

    void authenticateActionWithMFA(`grant-role/${uuid}/${role}`).then((u2fPack) => {
      void grantRoleMutation.call({
        uuid,
        role,
        u2fPack
      }).then(() => {
        alert('Success');
      }).catch((err: any) => {
        alert((err as MoopsyError).message);
      });
    });
  }, [authenticateActionWithMFA, grantRoleMutation]);

  return (
    <Container>
      {getUsersQuery.data != null
        ? <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5
        }}>
          {getUsersQuery.data.users.map((user) => (
            <div key={user.uuid} style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
              <div style={{ width: 320 }}>
                {user.uuid}
              </div>
              <div style={{ width: 200 }}>
                {user.verifiedDetails != null
                  ? <div>
                    {user.verifiedDetails.firstName} {user.verifiedDetails.lastName} <FontAwesomeIcon icon={faBadgeCheck}/>
                  </div>
                  : user.name ?? 'No Name'}
              </div>
              <div style={{ width: 500 }}>
                {user.email?.address ?? 'No Email'}/{user.hiylloID} fed: {user.federationDetails?.slug}
              </div>
              <button onClick={() => commandeerAccount(user.uuid)}>Commandeer</button>
              <button onClick={() => giveRole(user.uuid)}>Give Role</button>
              <button onClick={() => changeUserPassword(user.uuid)}>Set Password</button>
              <button>Delete User</button>
              {user.hiylloID == null || !user.hiylloID.endsWith('@hiyllo.io')
                ? <button onClick={() => upgradeToEmployee(user.uuid)}>Upgrade to Employee</button>
                : null}
            </div>
          ))}
        </div>
        : <LoadingSpinner/>
      }
    </Container>
  );
});
