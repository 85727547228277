/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { createJSONSchemaFromRequiredProperties } from "../../types/json-schema";
import { U2FPack, U2FPackSchema } from "../../types/u2f-pack";

export type ParamsType = { u2fPack: U2FPack };
export type ResponseType = void;
export const Endpoint = 'auth/disable-u2f';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}

export const paramsSchema = createJSONSchemaFromRequiredProperties({
  u2fPack: U2FPackSchema
});