/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
// @ts-ignore[module-not-found]
import { PublicKeyCredentialCreationOptionsJSON } from '@simplewebauthn/typescript-types';
import { VoidSchemaType } from '../../types/json-schema';

export type ParamsType = null;
export type ResponseType = { options: PublicKeyCredentialCreationOptionsJSON };
export const Endpoint = 'auth/start-u2f-registration';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}

export const paramsSchema = VoidSchemaType;