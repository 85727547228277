/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  name: string;
  legalOwner: string;
};
export type ResponseType = {
  uuid: string;
}
export const Endpoint = 'organizations/create-organization';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}export const paramsSchema = {"type":"object","properties":{"name":{"type":"string"},"legalOwner":{"type":"string"}},"required":["legalOwner","name"],"$schema":"http://json-schema.org/draft-07/schema#"}