/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { createJSONSchemaFromRequiredProperties, StringSchemaType } from "./json-schema";

export type HashedString = {
    digest: string,
    algorithm: string,
};

export const HashedStringSchemaType = createJSONSchemaFromRequiredProperties({
    digest: StringSchemaType,
    algorithm: StringSchemaType
});