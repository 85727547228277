import React from 'react';
import {
  getSavedTokenPackage, seamlessAuth, seamlessClient
} from '../seamless-client';
import { Login } from '../views/login';
import { AuthExtensionStatus, TransportStatus } from '@moopsyjs/react/main';
import { LoadingPage } from '@hiyllo/ux/standard-loader';

export const AuthenticationProvider = React.memo(function AuthenticationProvider (props: React.PropsWithChildren): JSX.Element {
  const isAuthenticated = getSavedTokenPackage() != null;
  const authState = seamlessAuth.useAuthState();
  const authStatus = seamlessAuth.useAuthStatus();
  const connection = seamlessClient.use.transportStatus();

  console.log('>>>', {
    isAuthenticated,
    authState,
    authStatus,
    connection
  });

  if (isAuthenticated && (authStatus === AuthExtensionStatus.loggingIn || connection !== TransportStatus.connected)) {
    return <LoadingPage/>;
  }

  return <>{props.children}</>;
});
