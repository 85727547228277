/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { createJSONSchemaFromRequiredProperties, StringSchemaType } from "../../types/json-schema";

export type ParamsType = { app: string, SAMLRequest?: string, relayState?: string };
export type ResponseType = { SAMLResponse: string, acsUrl: string, binding?: 'redirect' | 'post' };
export const Endpoint = 'auth/perform-sso-login';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}

export const paramsSchema = createJSONSchemaFromRequiredProperties({
  app: StringSchemaType,
}, {
  SAMLRequest: StringSchemaType,
  relayState: StringSchemaType
});