/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  sessionUUID: string;
  code: string;
};
export interface ResponseType { token: string }
export const Endpoint = 'register/register-with-email-otp';
export const Method = 'POST';
export const isPublic = true;

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"sessionUUID":{"type":"string"},"code":{"type":"string"}},"required":["code","sessionUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}