import React from 'react';
import { useParams } from 'react-router-dom';
import * as PerformSSOLoginBlueprint from '../blueprints/internal-auth/login-to-internal';
import { LoadingPage } from '@hiyllo/ux/standard-loader';
import { seamlessClient } from '../seamless-client';

export const InternalAuth = (): JSX.Element => {
  const performSSOLoginMutation = seamlessClient.useMutation<PerformSSOLoginBlueprint.Plug>(PerformSSOLoginBlueprint);

  const params = useParams<{domain: string}>();

  React.useEffect(() => {
    const domain = params.domain;

    if (domain == null) {
      return;
    }

    if (domain === 'localhost') {
      if (!confirm('You are trying to login to localhost, are you sure you want to proceed?')) {
        return;
      }
    }

    const then = new window.URLSearchParams(window.location.search).get('then');

    void performSSOLoginMutation.call({ domain }).then(res => {
      window.location.href = `http${domain !== 'localhost:3000' ? 's' : ''}://${domain}/iauth?token=${res.token}&then=${then ?? '/'}`;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.domain]);

  return (
    <LoadingPage/>
  );
};
