import React from 'react';
import { useParams } from 'react-router-dom';
import * as PerformSSOLoginBlueprint from '../blueprints/sso/perform-sso-login';
import { LoadingPage } from '@hiyllo/ux/standard-loader';
import { seamlessClient } from '../seamless-client';
import { EmptySplash } from '@hiyllo/ux/empty-splash';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';

export const SSOLogin = (): JSX.Element => {
  const startedRef = React.useRef<boolean>(false);
  const performSSOLoginMutation = seamlessClient.useMutation<PerformSSOLoginBlueprint.Plug>(PerformSSOLoginBlueprint);
  const [error, setError] = React.useState<Error | null>(null);

  const params = useParams();

  React.useEffect(() => {
    const usp = new window.URLSearchParams(window.location.search);
    const samlRequestRaw = usp.get('SAMLRequest');
    const SAMLRequest = (samlRequestRaw != null) ? decodeURIComponent(samlRequestRaw) : undefined;
    const RelayState: string = (usp.get('RelayState') ?? '');
    const returnRedirect: string = (usp.get('returnRedirect') ?? '');
    const appId = params.appId;

    if (typeof appId !== 'string' || (SAMLRequest == null)) {
      return setError(new Error('Invalid AppID Specified'));
    }

    if (startedRef.current) return;
    startedRef.current = true;

    void performSSOLoginMutation.call({
      SAMLRequest,
      app: appId,
      relayState: RelayState
    }).then(({
      acsUrl, SAMLResponse, binding
    }) => {
      if (appId === 'google' || binding === 'post') {
        const p1: string = acsUrl;
        const p2 = (RelayState != null) ? `<input type="hidden" name="RelayState" value="${RelayState}" />` : '';
        const p3: string = SAMLResponse;

        document.write(`
              <html>
                  <body>
                    <form id="sso" method="post" action="${p1}" autocomplete="off">
                        <input type="hidden" name="SAMLResponse" value="${p3}" />
                        ${p2}
                    </form>
                  </body>
              </html>
          `);

        setTimeout(() => {
          document.forms[0].submit();
        }, 250);
      } else {
        window.location.href = acsUrl + '?' + new window.URLSearchParams({
          SAMLResponse,
          RelayState,
          returnRedirect
        }).toString();
      }
    }).catch(setError);
  }, []);

  if (error != null) {
    return (
      <EmptySplash
        icon={faExclamationTriangle}
        label='SSO Login Failed'
        hint={error.message}
      />
    );
  }

  return (
    <LoadingPage/>
  );
};
