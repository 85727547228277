import { useParams } from 'react-router-dom';

export function useFederationSlug (): string | null {
  const params = useParams<{ slug?: string }>();
  const host = window.location.host;
  if (host.endsWith('.f.hiyllo.cloud')) {
    return host.split('.')[0];
  } else {
    return params.slug ?? null;
  }
}
