import { ThemeProvider, type ThemeType } from '@hiyllo/ux/theme';
import React from 'react';
import { HiylloTokyoThemeDark } from '@hiyllo/ux/tokyo-theme';

export const UXThemeProvider = React.memo(function UXThemeProvider (
  props: React.PropsWithChildren
): JSX.Element {
  console.debug('Rendering <UXThemeProvider>');
  return <ThemeProvider theme={HiylloTokyoThemeDark}>{props.children}</ThemeProvider>;
});
