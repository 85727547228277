import React from 'react';
import { LoginUI } from '@hiyllo/ux/login-ui';

import * as GetPasswordSetTokenDetailsBP from '../blueprints/accounts/get-password-set-token-details';
import * as SetPasswordWithTokenBP from '../blueprints/accounts/set-password-with-token';
import { useParams } from 'react-router-dom';
import { seamlessClient, SESSION_TOKEN_KEY } from '../seamless-client';
import { EmptySplash } from '@hiyllo/ux/empty-splash';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { hashString } from '../accounts/helpers/hash-password';
import { LoadingPage } from '@hiyllo/ux/standard-loader';

export const SetPassword = React.memo(function SetPassword (): JSX.Element {
  const params = useParams<{token: string}>();

  const detailsQuery = seamlessClient.useQuery<GetPasswordSetTokenDetailsBP.Plug>(GetPasswordSetTokenDetailsBP, { token: params.token ?? '' });
  const setPasswordMutation = seamlessClient.useMutation<SetPasswordWithTokenBP.Plug>(SetPasswordWithTokenBP);

  const submit = React.useCallback(async (password: string) => {
    const response = await setPasswordMutation.call({
      token: params.token ?? '',
      password: await hashString(password)
    });

    window.localStorage.setItem(SESSION_TOKEN_KEY, JSON.stringify(response));

    if (detailsQuery.data?.returnURL != null) {
      window.location.href = detailsQuery.data.returnURL;
    }
  }, [detailsQuery.data, params.token, setPasswordMutation]);

  if (detailsQuery.isError) {
    return (
      <EmptySplash
        icon={faExclamationTriangle}
        label={detailsQuery.error.message}
      />
    );
  }

  if (detailsQuery.isLoading) {
    return (
      <LoadingPage/>
    );
  }

  return (
    <LoginUI
      key="obtain-new-password"
      authenticatorType='password'
      logoPath='https://cdn.hiyllo.net/logo/hiyllo/icon-gradient-logo-white.png'
      overrideHeader={detailsQuery.data.type === 'reset' ? 'Set New Password' : 'Set Your Password'}
      overrideAuthenticatorLabel={detailsQuery.data.type === 'reset' ? 'New Password' : 'Password'}
      fixedIdentifier={detailsQuery.data.fixedIdentifier}
      disableIdentifier={true}
      showAuthenticator={true}
      onSubmitAuthenticator={p => {
        void submit(p);
      }}
      isLoading={setPasswordMutation.isLoading}
    />
  );
});
