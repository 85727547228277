import React from 'react';
import * as StartAuthenticateActionBlueprint from '../blueprints/authorization/start-u2f-authorization';
import { startAuthentication } from '@simplewebauthn/browser';
import { U2FPack } from '../types/u2f-pack';
import { seamlessClient } from '../seamless-client';

// interface PropsType {
//   action: string
//   onAuthenticated: (params: U2FPack) => void
// }

export function useAuthenticateActionWithMFA (): ({authenticateActionWithMFA: (action: string) => Promise<U2FPack>}) {
  const startAuthenticateActionMutation = seamlessClient.useMutation<StartAuthenticateActionBlueprint.Plug>(StartAuthenticateActionBlueprint);

  const authenticateActionWithMFA = React.useCallback(async (action: string): Promise<U2FPack> => {
    const { options, challengeId } = await startAuthenticateActionMutation.call({ action });
    const credential = await startAuthentication(options);

    return {
      challengeId,
      credential
    };
  }, [startAuthenticateActionMutation]);

  return { authenticateActionWithMFA };
}

// export const AuthenticateActionWithMFA = (props: PropsType): JSX.Element => {
//   const [isAuthenticating, setIsAuthenticating] = React.useState<boolean>(false);
//   const [complete, setComplete] = React.useState<boolean>(false);
//   const selfQuery = useQuery<GetSelfBlueprint.Plug>(GetSelfBlueprint, null);
//   const startAuthenticateActionMutation = useMutation<StartAuthenticateActionBlueprint.Plug>(StartAuthenticateActionBlueprint);

//   const authenticateAction = async (): Promise<void> => {
//     setIsAuthenticating(true);
//     const { options, challengeId } = await startAuthenticateActionMutation.call({ action: props.action });
//     console.log(20, options);
//     const credential = await startAuthentication(options);

//     props.onAuthenticated({
//       challengeId,
//       credential
//     });

//     setComplete(true);
//   };

//   if (selfQuery.isLoading || selfQuery.isError) {
//     return <div>
//         Loading...
//     </div>;
//   }

//   return (
//     <div style={{
//       backgroundColor: '#2196f3',
//       color: 'white',
//       padding: 10
//     }}>
//       {complete
//         ? <div>
//             You have been authenticated.
//         </div>
//         : <>
//           <div>
//             Authenticate action: <b>{props.action}</b>
//           </div>
//           {isAuthenticating
//             ? startAuthenticateActionMutation.isLoading
//               ? 'Requesting authentication from server'
//               : 'Authenticate with MFA device'
//             : <button onClick={() => { void authenticateAction(); }}>Authenticate</button>
//           }
//         </>}
//     </div>
//   );
// };

export {};
