import React from 'react';
import { useParams } from 'react-router-dom';
import * as CompleteTransferAuthBP from '../blueprints/login/complete-transfer-auth';
import { LoadingPage } from '@hiyllo/ux/standard-loader';
import { seamlessClient, SESSION_TOKEN_KEY } from '../seamless-client';

export const CompleteTransferAuth = (): JSX.Element => {
  const performSSOLoginMutation = seamlessClient.useMutation<CompleteTransferAuthBP.Plug>(CompleteTransferAuthBP);

  const params = useParams<{token: string}>();

  React.useEffect(() => {
    const token = params.token;

    if (token == null) {
      return;
    }

    const then = new window.URLSearchParams(window.location.search).get('then');

    void performSSOLoginMutation.call({ transferToken: token }).then(res => {
      window.localStorage.setItem(SESSION_TOKEN_KEY, JSON.stringify({ token: res.authToken }));
      if (then != null) {
        window.location.href = then;
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.token]);

  return (
    <LoadingPage/>
  );
};
