/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { HashedString, HashedStringSchemaType } from "../../types/hashed-string";
import { createJSONSchemaFromRequiredProperties } from "../../types/json-schema";

export interface ParamsType { oldPassword: HashedString, newPassword: HashedString, logoutOtherSessions: boolean }
export type ResponseType = { token: string | null };
export const Endpoint = 'security/change-self-password';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}

export const paramsSchema = createJSONSchemaFromRequiredProperties({
  oldPassword: HashedStringSchemaType,
  newPassword: HashedStringSchemaType,
  logoutOtherSessions: { type: "boolean" },
});