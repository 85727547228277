/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = { token: string; };
export interface ResponseType {
  fixedIdentifier: string;
  type: "reset" | "initial-set";
  returnURL: string | null;
}
export const Endpoint = 'accounts/get-password-set-token-details';
export const Method = 'GET';
export const isPublic = true;

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}export const paramsSchema = {"type":"object","properties":{"token":{"type":"string"}},"required":["token"],"$schema":"http://json-schema.org/draft-07/schema#"}