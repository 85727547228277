/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { VoidSchemaType } from "../../types/json-schema";

export type ParamsType = null;
export type ResponseType = { clientSecret: string; sessionId: string; };
export const Endpoint = 'accounts/create-verification-session';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}

export const paramsSchema = VoidSchemaType;