import React from 'react';
import { AuthenticationProvider } from './authentication-provider';
import { Router } from './router';
import { UXThemeProvider } from './theme-provider';
import { DialogProvider } from '@hiyllo/ux/dialogs';
import { FontAwesomeIconCtx } from '@hiyllo/ux/platform';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WebPortalsProvider } from '@hiyllo/ux/web-portals';
import { FiberProvider } from 'its-fine';

export const Main = React.memo(function Main (): JSX.Element {
  return (
    <FiberProvider>
      <FontAwesomeIconCtx.Provider value={FontAwesomeIcon as any}>
        <WebPortalsProvider>
          <UXThemeProvider>
            <DialogProvider>
              <AuthenticationProvider>
                <Router/>
              </AuthenticationProvider>
            </DialogProvider>
          </UXThemeProvider>
        </WebPortalsProvider>
      </FontAwesomeIconCtx.Provider>
    </FiberProvider>
  );
});
