/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { createJSONSchemaFromRequiredProperties, StringSchemaType } from '../../types/json-schema';

export type ParamsType = { credentials: string };
export type ResponseType = void;
export const Endpoint = 'auth/complete-u2f-registration';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}

export const paramsSchema = createJSONSchemaFromRequiredProperties({
  credentials: StringSchemaType
});