import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  HashRouter,
  useParams
} from 'react-router-dom';
import { SSOLogin } from '../views/sso-login';
import { Dashboard } from '../views/dashboard';
import { CreateEmployeeAccount } from '../views/create-employee-account';
import { SetPassword } from '../views/set-password';
import { Organizations } from '../views/organizations';
import { UniversalAuth } from '../views/universal-auth';
import { InternalAuth } from '../views/internal-auth';
import { CreateFederation } from '../views/create-federation';
import { LoginFederation } from '../views/login-federation';
import { Login } from '../views/login';
import { seamlessAuth } from '../seamless-client';
import { AuthExtensionStatus } from '@moopsyjs/react/main';
import { VerifyTrust } from '../views/verify-trust';
import { CompleteTransferAuth } from '../views/complete-transfer-auth';

export const Router = React.memo(function Router (): JSX.Element {
  const isAuthenticated = seamlessAuth.useAuthStatus() === AuthExtensionStatus.loggedIn;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/sso/SingleSignOnService/:appId" element={isAuthenticated ? <SSOLogin/> : <Login/>}/>
        <Route path="/sp/:token" element={<SetPassword/>}/>
        <Route path="/ta/:token" element={<CompleteTransferAuth/>}/>
        <Route path="/organizations" element={isAuthenticated ? <Organizations/> : <Login/>}/>
        <Route path="/universal-auth/:uuid" element={isAuthenticated ? <InternalAuth/> : <Login/>}/>
        <Route path="/f/create" element={<CreateFederation/>}/>
        <Route path="/f/login/:slug" element={<Login/>}/>
        <Route path="/f/login" element={<LoginFederation/>}/>
        <Route path="/dev/vt/:trustId" element={isAuthenticated ? <VerifyTrust/> : <Login/>}/>
        <Route path="/ia/:domain/f/:slug" element={isAuthenticated ? <InternalAuth/> : <Login/>}/>
        <Route path="/ia/:domain" element={isAuthenticated ? <InternalAuth/> : <Login/>}/>
        <Route path="*" element={isAuthenticated ? <Dashboard/> : <Login/>}/>
      </Routes>
    </BrowserRouter>
  );
});
