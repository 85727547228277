/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable require-await */
import { MoopsyClient, MoopsyClientAuthExtension } from '@moopsyjs/react';
import EJSON from 'ejson';
import axios from 'axios';

import * as AuthSpec from './types/AuthSpec';

export const SESSION_TOKEN_KEY = 'hiylloauth';

export function getRootURL (): string {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:3001';
  }

  return '';
}

export function getSavedTokenPackage (): { token: string } | null {
  const savedTokenPackage = window.localStorage.getItem(SESSION_TOKEN_KEY);

  if (savedTokenPackage != null) {
    const { token } = EJSON.parse(
      savedTokenPackage
    );

    return { token };
  }

  return null;
}

export const seamlessClient = new MoopsyClient({
  socketUrl: getRootURL(),
  axios
});
export const seamlessAuth = new MoopsyClientAuthExtension<AuthSpec.Typings>(
  seamlessClient,
  {
    autoLoginFunction: async () => {
      return getSavedTokenPackage();
    }
  }
);

seamlessAuth.on.authError(() => {
  window.localStorage.clear();
  window.location.reload();
});
