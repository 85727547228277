import React from 'react';

import * as VerifyTrustBP from '../blueprints/dev/verify-trust';
import { useParams } from 'react-router-dom';
import { seamlessClient } from '../seamless-client';
import { useAuthenticateActionWithMFA } from './authenticate-action-with-mfa';
import { MoopsyError } from '@moopsyjs/core/main';

export const VerifyTrust = React.memo(function ValidateTrust (): JSX.Element {
  const { trustId } = useParams<{ trustId: string }>();
  const verifyTrustMutation = seamlessClient.useMutation<VerifyTrustBP.Plug>(VerifyTrustBP);
  const [message, setMessage] = React.useState<string>('Verifying...');

  const authWithMFA = useAuthenticateActionWithMFA();

  React.useEffect(() => {
    if (trustId == null) {
      throw new Error('trustId is required');
    }

    void authWithMFA.authenticateActionWithMFA(`verify-trust::${trustId}`).then(res => {
      void verifyTrustMutation.call({
        trustId,
        mfa: res
      }).then(() => {
        setMessage('Trust verified successfully');
      }).catch((err) => {
        setMessage((err as MoopsyError).message);
      });
    }).catch(() => {
      setMessage('MFA authentication failed');
    });
  }, []);

  return (
    <div>
      {message}
    </div>
  );
});
