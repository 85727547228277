/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { ExtendedUserType } from "../../types/extended-user-type";
import { WithStringId } from "../../types/utils";

export type ParamsType = null;
export type ResponseType = { users: Array<ExtendedUserType>; };
export const Endpoint = "admin/get-users";
export const Method = "GET";

export type Plug = {
    params: ParamsType,
    response: ResponseType,
    method: typeof Method,
    endpoint: typeof Endpoint,
};export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}