/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { createJSONSchemaFromRequiredProperties, StringSchemaType, VoidSchemaType } from './json-schema';

export interface AuthenticationExtensionsClientOutputs {
    appid?: boolean;
    credProps?: {
        rk?: boolean
    };
    uvm?: Array<number[]>;
}

export interface AuthenticatorAssertionResponseJSON {
    authenticatorData: string;
    clientDataJSON: string;
    signature: string;
    userHandle?: string;
}

export interface AuthenticationCredentialJSON {
    rawId: string;
    response: AuthenticatorAssertionResponseJSON;
    clientExtensionResults: AuthenticationExtensionsClientOutputs;
    readonly id: string;
    readonly type: "public-key";
}

export type U2FPack = { challengeId: string, credential: AuthenticationCredentialJSON };

const AuthenticationCredentialJSONSchema = createJSONSchemaFromRequiredProperties({
    rawId: StringSchemaType,
    id: StringSchemaType,
    type: StringSchemaType,    
    response: createJSONSchemaFromRequiredProperties({
        authenticatorData: StringSchemaType,
        clientDataJSON: StringSchemaType,
        signature: StringSchemaType,
        
    }, {
        userHandle: StringSchemaType,
    }),
    clientExtensionResults: createJSONSchemaFromRequiredProperties({}, {
        appid: {type:"boolean"},
        uvm: {type:"array", items:{type:"array", items:{type:"number"}}},
        credProps: createJSONSchemaFromRequiredProperties({}, {
            rk: {type:"boolean"}
        })
    })
}, {
    authenticatorAttachment: { type: ["string", "null"] }
});

export const U2FPackSchema = createJSONSchemaFromRequiredProperties({
    challengeId: StringSchemaType,
    credential: AuthenticationCredentialJSONSchema
});