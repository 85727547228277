/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = { slug: string; };
export type ResponseType = { valid: boolean; }
export const Endpoint = 'federation/query-federation';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}
export const isPublic = true;export const paramsSchema = {"type":"object","properties":{"slug":{"type":"string"}},"required":["slug"],"$schema":"http://json-schema.org/draft-07/schema#"}