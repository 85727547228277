/* eslint-disable no-useless-call */
import React from 'react';
import { styled } from '@hiyllo/ux/styled';

import * as GetSelfBP from '../blueprints/accounts/get-self';
import * as LogoutBP from '../blueprints/accounts/logout';
import * as DisableU2FBP from '../blueprints/security/disable-u2f';
import * as ChangeSelfPasswordBP from '../blueprints/security/change-self-password';
import * as CreateVerificationSessionBP from '../blueprints/accounts/create-verification-session';
import * as CompleteIdentityVerificationBP from '../blueprints/accounts/complete-identity-verification';

import * as ListOrganizationsBP from '../blueprints/organizations/list-organizations';
import * as CreateOrganizationBP from '../blueprints/organizations/create-organization';
import * as ListSSOAppsBP from '../blueprints/sso-apps/list-sso-apps';
import * as CreateSSOAppBP from '../blueprints/sso-apps/create-sso-app';
import { SESSION_TOKEN_KEY, seamlessClient } from '../seamless-client';
import { MFARegistrationPanel } from './mfa-settings';
import { Admin } from './admin';
import { LoadingPage } from '@hiyllo/ux/standard-loader';
import { useAuthenticateActionWithMFA } from './authenticate-action-with-mfa';
import { loadStripe } from '@stripe/stripe-js';

import { Typography } from '@hiyllo/ux/typography';
import { Button } from '@hiyllo/ux/button';
import { LoginUI } from '@hiyllo/ux/login-ui';
import { hashString } from '../accounts/helpers/hash-password';
import { MoopsyError } from '@moopsyjs/core';
import {
  useAlertDialog, useShowConfirmationDialog, useShowDialog
} from '@hiyllo/ux/dialogs';
import { EmptySplash } from '@hiyllo/ux/empty-splash';
import {
  faBadgeCheck, faCheck, faExclamationTriangle, faShieldCheck
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@hiyllo/ux/input';
import { Organization } from '../types/organization';
import { LoadingSpinner } from '@hiyllo/ux/loading-spinner';

const Container = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  color: $theme.foreground,
  height: 'calc(100% - 80px)',
  fontFamily: 'hiyllo',
  paddingTop: 40,
  paddingBottom: 40,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingLeft: 50,
  gap: 10
}));

const Inner = styled('div', ({ $theme }) => ({
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  height: '100%'
}));

const LabeledDetail = React.memo(function LabeledDetail ({ label, children }: { label: string, children: React.ReactNode }): JSX.Element {
  return (
    <div>
      <div style={{
        fontFamily: 'hiyllo',
        fontSize: 15
      }}>{label}</div>
      <div style={{
        fontFamily: 'hiyllo',
        fontSize: 20
      }}>
        {children}
      </div>
    </div>
  );
});

const BadgeContainer = styled('div', ({ $theme }) => ({
  background: $theme.background3,
  padding: 7.5,
  borderRadius: 5
}));

const CreateOrganizationPanel = React.memo(function CreateOrganizationPanel (): JSX.Element {
  const createOrganizationMutation = seamlessClient.useMutation<CreateOrganizationBP.Plug>(CreateOrganizationBP);
  const [name, setName] = React.useState('');
  const [legalOwner, setLegalOwner] = React.useState('');
  const showAlertDialog = useAlertDialog();
  const showConfirm = useShowConfirmationDialog();

  const submit = React.useCallback(async () => {
    if (name.length < 3) {
      void showAlertDialog({
        title: 'Error',
        message: 'Organization name must be at least 3 characters long.'
      });
      return;
    }

    if (legalOwner.length < 3) {
      void showAlertDialog({
        title: 'Error',
        message: 'Legal owner name must be at least 3 characters long.'
      });
      return;
    }

    if (!(legalOwner.toLowerCase().includes('inc') || legalOwner.toLowerCase().includes('llc'))) {
      const confirmed = await showConfirm({
        title: 'Just to confirm',
        message: `The legal owner name "${legalOwner}" does not contain "Inc" or "LLC". Please make sure this is the legal name of the company that owns this application, or the full name of the owner if publishing as an individual.`
      });

      if (!confirmed) {
        return;
      }
    }

    await createOrganizationMutation.call({
      name,
      legalOwner
    }).then(() => {
      window.location.reload();
    });
  }, [createOrganizationMutation, legalOwner, name, showAlertDialog, showConfirm]);

  return (
    <div style={{ width: '100%' }}>
      <Input label='Organization Name' value={name} onChangeValue={setName}/>

      <div style={{ height: 15 }}/>

      <Input label='Legal Owner' value={legalOwner} onChangeValue={setLegalOwner} />
      <div style={{ height: 5 }}/>
      <Typography.Label style={{ opacity: 0.5 }}>This will be public</Typography.Label>

      <div style={{ height: 15 }}/>

      <div style={{ display: 'flex' }}>
        <Button label="Create" onClick={() => { void submit(); }} isLoading={createOrganizationMutation.isLoading} />
      </div>
    </div>
  );
});

const CreateSSOAppPanel = React.memo(function CreateSSOAppPanel (props: {organizationUUID: string}): JSX.Element {
  const createSSOAppMutation = seamlessClient.useMutation<CreateSSOAppBP.Plug>(CreateSSOAppBP);
  const [appName, setAppName] = React.useState('');
  const [redirectURI, setRedirectURI] = React.useState('');
  const showAlertDialog = useAlertDialog();
  const showConfirm = useShowConfirmationDialog();

  const submit = React.useCallback(async () => {
    if (appName.length < 3) {
      void showAlertDialog({
        title: 'Error',
        message: 'App name must be at least 3 characters long.'
      });
      return;
    }

    if (redirectURI.length < 20) {
      void showAlertDialog({
        title: 'Error',
        message: 'Provide a valid redirect URI.'
      });
      return;
    }

    if (!redirectURI.startsWith('https://')) {
      void showAlertDialog({
        title: 'Error',
        message: 'Redirect URI must be HTTPS.'
      });
      return;
    }

    await createSSOAppMutation.call({
      appName,
      redirectURI,
      organizationUUID: props.organizationUUID
    }).then((res) => {
      void showAlertDialog({
        title: 'SSO App Created',
        message: `Your app has been created, and a token has been created. Save this token now, you will not be able to see it again. Your secret token is: ${res.secretToken}`
      }).then(() => {
        window.location.reload();
      });
    });
  }, [appName, createSSOAppMutation, props.organizationUUID, redirectURI, showAlertDialog]);

  return (
    <div style={{ width: '100%' }}>
      <Typography.SubHeader>Create your first SSO app</Typography.SubHeader>

      <div style={{ height: 15 }}/>

      <Input label='App Name' value={appName} onChangeValue={setAppName}/>

      <div style={{ height: 15 }}/>

      <Input label='Redirect URI' value={redirectURI} onChangeValue={setRedirectURI} />
      <div style={{ height: 5 }}/>
      <Typography.Label style={{ opacity: 0.5 }}>User will be redirected to this URL + <span style={{ fontFamily: 'monospace' }}>?hiylloUAT=...</span></Typography.Label>

      <div style={{ height: 15 }}/>

      <div style={{ display: 'flex' }}>
        <Button label="Create" onClick={() => { void submit(); }} isLoading={createSSOAppMutation.isLoading} />
      </div>
    </div>
  );
});

const OrganizationPanel = React.memo(function OrganizationPanel ({ organization }: { organization: Organization }): JSX.Element {
  const listSSOAppsQuery = seamlessClient.useQuery<ListSSOAppsBP.Plug>(ListSSOAppsBP, { organizationUUID: organization.uuid });

  return (
    <div style={{ width: '100%' }}>
      <Typography.SubHeader>{organization.name}</Typography.SubHeader>
      {listSSOAppsQuery.isLoading
        ? <LoadingSpinner/>
        : (listSSOAppsQuery.error != null)
          ? <div>Error</div>
          : <>
            {listSSOAppsQuery.data.apps.length === 0
              ? <CreateSSOAppPanel organizationUUID={organization.uuid}/>
              : listSSOAppsQuery.data.apps.map((app) => (
                <div key={app.uuid}>
                  <Typography.Label>{app.appName}</Typography.Label>
                  <div>
                    <span style={{ userSelect: 'none' }}>To sign in, direct your users to: </span>https://www.hiyllo.cloud/universal-auth/{app.uuid}
                  </div>
                  <div>
                    <span style={{ userSelect: 'none' }}>After sign in, users will be redirected to: </span>{app.redirectURI}
                  </div>
                </div>
              ))}
          </>}
    </div>
  );
});

export const Organizations = React.memo(function Organizations (): JSX.Element {
  const listOrganizationsQuery = seamlessClient.useQuery<ListOrganizationsBP.Plug>(ListOrganizationsBP, null);
  const { authenticateActionWithMFA } = useAuthenticateActionWithMFA();
  const showDialog = useShowDialog();

  if (listOrganizationsQuery.isLoading) {
    return (
      <LoadingPage/>
    );
  }

  if (listOrganizationsQuery.isError) {
    return (
      <EmptySplash
        icon={faExclamationTriangle}
        label='Error'
        hint='An error occurred while fetching your organizations. Please try again later.'
      />
    );
  }

  const { organizations } = listOrganizationsQuery.data;

  return (
    <Container>
      <img
        src="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient-logo-white.png"
        style={{
          height: 32,
          width: 'auto'
        }}
      />
      <div style={{
        height: 0,
        flexGrow: 1
      }}/>
      <Typography.Header>Your Organizations</Typography.Header>
      <Typography.Paragraph>Organizations allow you to enable Login with Hiyllo</Typography.Paragraph>

      {organizations.length === 0
        ? <CreateOrganizationPanel/>
        : organizations.map((organization) => (
          <OrganizationPanel key={organization.uuid} organization={organization}/>
        ))}

      <div style={{
        height: 0,
        flexGrow: 1
      }}/>
      <div style={{
        height: 32,
        flexGrow: 0,
        flexShrink: 0
      }}/>
    </Container>
  );
});
